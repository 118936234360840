import request from './request'

export function getList(data) {
  return request({
    url: '/order.myorder/listPage',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function setStartime(data) {
  return request({
    url: '/order.myorder/setStartime',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

/**
 * 标记为重点客户
 * @param {*} data
 * @returns
 */
export function setImportance(data) {
  return request({
    url: '/order.order/importance',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

/**
 * 结束案件
 * @param {*} data
 * @returns
 */
export function endFinishOrder(data) {
  return request({
    url: '/order.order/endFinishOrder',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
