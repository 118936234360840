<template>
  <div class="client-page">
    <c-search searchTitle="模糊查询" @search="clientSearch" placeholder="姓名/电话" :isDate="false"></c-search>
    <c-navigation @change="caseCategory" title="客户类别" :list="clientCategory" isBorder></c-navigation>

    <!-- 第一行类别 -->
    <div class="list1">
      <c-navigation v-if="clientTypeList.length > 0" @change="changeClassify" title="客户分类" :list="clientTypeList" isBorder></c-navigation>
      <c-navigation @change="changeSituation" title="跟进情况" :list="followList" isBorder></c-navigation>
    </div>
    <c-navigation v-if="situation.length > 0" @change="changeMatter" title="案件情况" :list="situation" isBorder></c-navigation>
    <c-operate>
      <template #right>
        <div class="btn-group">
          <div class="btn courtDate" @click="open()">开庭时间</div>
        </div>
      </template>
    </c-operate>
    <!-- <div class="btn-group">
      <div class="btn courtDate" @click="open()">开庭时间</div>
    </div> -->
    <!-- 表格 -->
    <c-table :data="tableData">
      <el-table-column align="center" label="选择">
        <template #default="{ row }">
          <el-checkbox @change="myClientCheckbox(row)"></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="username" label="联系人"> </el-table-column>
      <el-table-column align="center" prop="mobile" label="电话" width="130"> </el-table-column>
      <el-table-column align="center" prop="age" label="年龄"> </el-table-column>
      <el-table-column align="center" prop="bg_sex" label="性别"> </el-table-column>
      <el-table-column align="center" prop="company" label="公司"> </el-table-column>
      <el-table-column align="center" prop="typelist" label="客户分类"> </el-table-column>
      <el-table-column align="center" prop="orderInfoRemark" label="案件情况"> </el-table-column>
      <el-table-column align="center" prop="yw_admin_id" label="业务人员"> </el-table-column>
      <el-table-column align="center" prop="sourcelist" label="客户来源"> </el-table-column>
      <el-table-column align="center" prop="startime" label="开庭时间" width="180"> </el-table-column>
      <el-table-column align="center" label="重点">
        <template #default="{ row }">
          <div class="flag" v-if="row.yw_importance == 1">
            <img :src="$icon('vital')" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="date" label="操作" width="180">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list edit" @click="check(row)">查看</p>
            <p class="operate-list" @click="findOrderInfo(0, row.id)">跟进信息</p>
          </div>
        </template>
      </el-table-column>
    </c-table>
    <!-- 分页 -->
    <c-pagination :total="10"></c-pagination>
    <!-- 查看客户 -->
    <c-drawer v-model="showClient" :width="35" title="查看案件">
      <div class="newClient">
        <p class="title">#客户信息</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">姓名：</p>
            <span class="list-content">{{ viewTableData.username }}</span>
          </div>
          <div class="w-50">
            <p class="name">联系方式：</p>
            <span class="list-content">{{ viewTableData.username }}</span>
          </div>
          <div class="w-50">
            <p class="name">年龄：</p>
            <span class="list-content">{{ viewTableData.username }}</span>
          </div>
          <div class="w-50">
            <p class="name">性别：</p>
            <span class="list-content">{{ viewTableData.sexlist }}</span>
          </div>
          <div class="w-50">
            <p class="name">客户分类：</p>
            <span class="list-content">{{ viewTableData.typelist }}</span>
          </div>
          <div class="w-50">
            <p class="name">起诉/应诉：</p>
            <span class="list-content">{{ viewTableData.lawsuitlist }}</span>
          </div>
          <div class="w-50">
            <p class="name">客户来源：</p>
            <span class="list-content">{{ viewTableData.sourcelist }}</span>
          </div>

          <div class="w-80">
            <p class="name">客户地址：</p>
            <span class="list-content">{{ viewTableData.addr }}</span>
          </div>
          <div class="w-80">
            <p class="name">公司名称：</p>
            <span class="list-content">{{ viewTableData.company }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.renark }}</span>
          </div>
        </div>
        <p class="title">#案件信息</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">案件类型：</p>
            <span class="list-content">{{ viewTableData.aj_statuslist }}</span>
          </div>
          <div class="w-50">
            <p class="name">标的额：</p>
            <span class="list-content">{{ viewTableData.aj_money }}</span>
          </div>
          <div class="w-50" v-if="viewTableData.aj_statuslist == 0">
            <p class="name">案件情况：</p>
            <span class="list-content">{{ viewTableData.aj_ms_statuslist }}</span>
          </div>
          <div class="w-50" v-if="viewTableData.aj_statuslist == 0">
            <p class="name">案件类型：</p>
            <span class="list-content">{{ viewTableData.aj_ms_typelist }}</span>
          </div>
          <template v-if="viewTableData.aj_statuslist == 1">
            <div class="w-50">
              <p class="name">案件阶段：</p>
              <span class="list-content">{{ viewTableData.aj_xs_statuslist }}</span>
            </div>
            <div class="w-50">
              <p class="name">罪名：</p>
              <span class="list-content">{{ viewTableData.aj_xs_contentlist }}</span>
            </div>
            <div class="w-80">
              <p class="name">委托人与被害人关系：</p>
              <span class="list-content">{{ viewTableData.aj_xs_tylelist }}</span>
            </div>
          </template>
          <div class="w-80">
            <p class="name">案号：</p>
            <span class="list-content">{{ viewTableData.aj_code }}</span>
          </div>
          <div class="w-80">
            <p class="name">案发时间：</p>
            <span class="list-content">{{ viewTableData.aj_date }}</span>
          </div>
          <div class="w-80" v-if="viewTableData.aj_statuslist == 0">
            <p class="name">案由：</p>
            <span class="list-content">{{ viewTableData.aj_ms_contentlist }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.aj_remark }}</span>
          </div>
        </div>
        <p class="title">#原告</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">姓名：</p>
            <span class="list-content">{{ viewTableData.yg_name }}</span>
          </div>
          <div class="w-50">
            <p class="name">年龄：</p>
            <span class="list-content">{{ viewTableData.yg_age }}</span>
          </div>
          <div class="w-50">
            <p class="name">联系方式：</p>
            <span class="list-content">{{ viewTableData.yg_mobile }}</span>
          </div>
          <div class="w-50">
            <p class="name">性别：</p>
            <span class="list-content">{{ viewTableData.yg_sex }}</span>
          </div>
          <div class="w-80">
            <p class="name">身份证号：</p>
            <span class="list-content">{{ viewTableData.yg_idnum }}</span>
          </div>
          <div class="w-80">
            <p class="name">地区：</p>
            <span class="list-content">{{ viewTableData.yg_area }}</span>
          </div>
          <div class="w-80">
            <p class="name">地址：</p>
            <span class="list-content">{{ viewTableData.yg_addr }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.yg_remark }}</span>
          </div>
        </div>
        <p class="title">#被告</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">姓名：</p>
            <span class="list-content">{{ viewTableData.bg_name }}</span>
          </div>
          <div class="w-50">
            <p class="name">年龄：</p>
            <span class="list-content">{{ viewTableData.bg_age }}</span>
          </div>
          <div class="w-50">
            <p class="name">联系方式：</p>
            <span class="list-content">{{ viewTableData.bg_mobile }}</span>
          </div>
          <div class="w-50">
            <p class="name">性别：</p>
            <span class="list-content">{{ viewTableData.bg_sex }}</span>
          </div>
          <div class="w-80">
            <p class="name">身份证号：</p>
            <span class="list-content">{{ viewTableData.bg_idnum }}</span>
          </div>
          <div class="w-80">
            <p class="name">地区：</p>
            <span class="list-content">{{ viewTableData.bg_area }}</span>
          </div>
          <div class="w-80">
            <p class="name">地址：</p>
            <span class="list-content">{{ viewTableData.bg_addr }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.bg_remark }}</span>
          </div>
        </div>
      </div>
    </c-drawer>

    <!-- 跟进信息 -->
    <c-drawer v-model="showFollow" :width="35" title="跟进信息详情">
      <!-- 按钮切换 -->
      <div class="btn" style="margin-bottom: 30px">
        <div class="follow-up" @click="isfollowUpCheck" :class="{ active: isfollowUp }">跟进详情</div>
        <div class="schedule" @click="isScheduleCheck" :class="{ active: isSchedule }">进度详情</div>
      </div>
      <!-- 跟进详情 -->
      <div v-if="isfollowUp == true">
        <c-time-line :data="timeLine"></c-time-line>
        <div class="addTimeLine-box">
          <div class="addTimeLine" @click="showNewDetails = true">添加跟进</div>
        </div>
      </div>
      <!-- 进度详情 -->
      <div v-if="isSchedule == true">
        <c-time-line :data="timeLine"></c-time-line>
      </div>
    </c-drawer>
    <!-- 新建跟进 -->
    <c-dialog v-model="showNewDetails" title="新建跟进" :mask="false" @confirm="newDetailsConfirm">
      <c-upload @on-success="changeNewDetailsImgs" @on-remove="changeNewDetailsImgs" :isDocument="false" multiple :limit="6"></c-upload>
      <div style="display: flex">
        <p class="name" style="width: 60px">备注：</p>
        <el-input class="inp" v-model="addFollowupParam.remark" type="textarea" :rows="3" resize="none" placeholder="请输入内容"> </el-input>
      </div>
    </c-dialog>
    <!-- 选择开庭时间 -->
    <c-dialog v-model="showTime" title="开庭时间" :mask="true" @confirm="setTime">
      <c-date-picker @select="selectedDate" isTime></c-date-picker>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="listParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDrawer from '@/components/c-drawer'
import CDialog from '@/components/c-dialog'
import CTimeLine from '@/components/c-time-line'
import * as clientApi from '@/api/client.js'
import * as caseApi from '@/api/case'
import { getTypeList } from '@/api/common'
import config from "@/utils/config.js";
export default {
  name: 'Case',
  components: {
    CDrawer,
    CDialog,
    CTimeLine
  },
  data() {
    return {
      clientCategory: [
        {
          id: 2,
          name: '跟进客户',
          isChecked: true
        },
        {
          id: 3,
          name: '成交客户',
          isChecked: false
        }
      ],
      // 跟进情况
      followList: [
        {
          id: 0,
          isChecked: true,
          name: '加微信'
        },
        {
          id: 1,
          isChecked: false,
          name: '来访'
        },
        {
          id: 2,
          isChecked: false,
          name: '已成交'
        }
      ],
      dataList: [],
      // 客户分类
      clientTypeList: [],
      // 案件情况
      situation: [],
      // 分类
      listParam: {
        usmo: '', // 搜索的关键词
        type: 2, // 1:公海池 2:跟进客户 3:成交客户
        prestatus: 0, //跟进情况:0=加微信,1:来访 2:已成交
        typelist: '', //	 客户分类:0=A类,1=B类,2=C类,3=D类
        aj_ms_statuslist: '', // 民事 案件情况:0=未诉,1=财产保全,2=一审,3=二审,4=执行,5=终本,6=执恢,7=破产清算
        page: 1, // 分页页码，数字类型
        limit: 7 // 一页展示条数
      },
      contactsTotal: 0,
      addFollowupParam: {
        status: 0,
        order_id: '',
        images: '',
        remark: '',
        tempo: ''
      },
      addFollowupImages: [],
      showClient: false,
      datePicker: '', //开庭时间
      showFollow: false,
      showTime: false,
      isfollowUp: true, // 跟进详情
      isSchedule: false, //进度详情
      showNewDetails: false,
      myClientCheckboxArray: [],
      //选择客户
      tableData: [],
      viewTableData: {},
      // 跟进详情
      timeLine: []
    }
  },
  watch: {
    showFollow(newValue) {
      if (!newValue) {
        this.isfollowUp = true
        this.isSchedule = false
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.clientTypeList = await getTypeList(1)
      // this.classify = await getTypeList(1)
      this.situation = await getTypeList(5)
      this.getList(0, 0, 0)
    },
    /**
     * 客户类别
     */
    caseCategory(v) {
      this.listParam.type = v.id
      this.getList()
    },
    // 切换分类
    changeClassify(v) {
      this.listParam.typelist = v.id
      this.getList()
    },
    // 切换案件情况
    changeSituation(v) {
      this.listParam.prestatus = v.id
      this.getList()
    },
    // 切换事项
    changeMatter(v) {
      this.listParam.aj_ms_statuslist = v.id
      this.getList()
    },
    // 获取列表
    getList() {
      clientApi.getClientList(this.listParam).then(res => {
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    // 查看
    check(row) {
      this.showClient = true
      this.viewTableData = row
    },
    /**
     * @description: 修改跟进图片的列表
     * @param {*} file: 图片的列表数据
     */
    changeNewDetailsImgs(file) {
      this.addFollowupImages = file
    },
    /**
     * @description: 新建跟进的确认
     */
    newDetailsConfirm() {
      const imgs = []
      this.addFollowupImages.forEach(i => {
        const url = i.url.replace(config.baseUrl, '')
        imgs.push(url)
      })
      this.addFollowupParam.images = imgs.join(',')
      clientApi.addOrderInfo(this.addFollowupParam).then(res => {
        this._showMsg('新建成功', 'success')
        this.showNewDetails = false
        this.findOrderInfo(0, this.addFollowupParam.order_id)
      })
    },
    /**
     * @description: 切换客户类别
     * @param {Object} e: 切换后的值
     */
    changeClientCategory(e) { },
    /**
     * @description: 改变我的客户列表选中的值
     * @param {*} v: 改变的值
     */
    myClientCheckbox(v) {
      const i = this.myClientCheckboxArray.indexOf(v.id)
      if (i !== -1) {
        this.myClientCheckboxArray.splice(i, 1)
      } else {
        this.myClientCheckboxArray.push(v.id)
      }
    },
    // 跟进消息
    follow(row) {
      this.showFollowUpInfoDetails = true
    },
    //开庭时间
    open() {
      if (this.myClientCheckboxArray.length == 1) {
        this.showTime = true
      } else if (this.myClientCheckboxArray.length < 1) {
        this._showMsg('请选择一个客户')
      } else {
        this._showMsg('仅能选择一个客户')
      }
    },
    /**
     * @description: 选择开庭的时间
     * @param {*} time: 时间
     */
    selectedDate(time) {
      this.datePicker = time
    },
    /**
     * @description: 设置开庭时间
     */
    setTime() {
      const param = {
        id: this.myClientCheckboxArray[0],
        startime: this.datePicker
      }
      caseApi.setStartime(param).then(res => {
        this.showTime = false
        this.getList()
        this._showMsg('设置成功', 'success')
      })
    },
    // 选择时间
    dateTimePicker() {
      if (this.datePicker) {
        let data = `${this.datePicker.getFullYear()}-${(this.datePicker.getMonth() + 1).toString().padStart(2, 0)}-${this.datePicker.getDate().toString().padStart(2, 0)}`
        if (this.selectedTable.length > 0) {
          this.selectedTable.forEach(e => {
            e.time = data
            this.showDialog = false
          })
        }
      } else {
        this.$message({
          message: '警告哦，请选择开庭时间',
          type: 'warning'
        })
        return
      }
    },
    /**
     * @description: 查询客户跟进/进度信息
     * @param {*} order_id: 要查询的 ID
     */
    findOrderInfo(type, order_id) {
      if (order_id) {
        this.addFollowupParam.order_id = order_id
      }
      this.showFollow = true
      const param = {
        type,
        order_id: order_id || this.addFollowupParam.order_id
      }
      clientApi.findOrderInfo(param).then(res => {
        this.timeLine = res.data
      })
    },
    /**
     * @description: 切换到跟进详情
     */
    isfollowUpCheck() {
      this.isfollowUp = true
      this.isSchedule = false
      this.findOrderInfo(0)
    },
    /**
     * @description: 切换到进度详情
     */
    isScheduleCheck() {
      this.isSchedule = true
      this.isfollowUp = false
      this.findOrderInfo(1)
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.listParam.page = i
      this.getList()
    },
    /**
     * @description: 客户查询
     * @param {*} e: 要查询的条件
     */
    clientSearch(e) {
      this.listParam.usmo = e.keyWord
      this.getList()
    }
  }
}
</script>
<style lang="scss">
.el-drawer {
  width: 41rem !important;
  border-radius: 2rem 0rem 0rem 2rem;
}
.el-drawer__body {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}
.el-table__body tr:hover > td.el-table__cell {
  background-color: #eaf0f0;
}
</style>

<style lang="scss" scoped>
.client-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}

.list1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.78rem 0;
  .btn {
    margin-right: 1.77rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.83rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 1;
    width: 5.61rem;
    height: 1.95rem;
    border-radius: 0.31rem;
  }
  .courtDate {
    background: linear-gradient(225deg, #fe626f, #fc394a);
  }
}
.edit {
  color: #f6184c;
}
.follow {
  color: #179d92;
}
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flag {
  img {
    width: 0.89rem;
    height: 1.09rem;
  }
}
.newClient {
  .title {
    width: 40rem;
    padding: 0 1.5rem;
    font-size: 0.94rem;
    font-weight: 400;
    color: #333333;
    line-height: 1;
  }

  .newClientFrom {
    padding: 1.55rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .w-50 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 50%;
    }
    .w-80 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 80%;
    }
    .w1-80 {
      display: flex;
      align-items: flex-start;
      margin: 0.5rem 0;
      width: 95%;
      p {
        padding: 5px 0;
      }
      :deep() .el-textarea.is-disabled .el-textarea__inner {
        font-size: 0.84rem;
        font-weight: 400;
        color: #333333;
      }
    }
    .name {
      font-size: 0.94rem;
      flex-shrink: 0;
      text-align: right;
      width: 5.5rem;
      color: #333;
    }
    .list-content {
      font-size: 0.94rem;
      color: #333;
    }
    .align-start {
      align-items: flex-start;
    }
  }

  .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4.5rem 0 20rem;
    .btn {
      text-align: center;
      font-size: 0.94rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 2.2rem;
      width: 11.25rem;
      height: 2.2rem;
      background: linear-gradient(215deg, #1db8ab, #179d92);
      border-radius: 0.78rem;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  div {
    width: 7.78rem;
    height: 2.2rem;
    text-align: center;
    line-height: 2.2rem;
    border: 1px solid #eeeeee;
    cursor: pointer;
    background-color: #eeeeee;
    box-sizing: border-box;
    font-size: 0.94rem;
    color: #000000;
    font-weight: 400;
  }
  .follow-up {
    border-radius: 1rem 0rem 0rem 1rem;
  }

  .schedule {
    border-radius: 0rem 1rem 1rem 0rem;
  }
  .active {
    border: none;
    color: #f5fafd;
    background: linear-gradient(109deg, #fc9940, #f37902);
  }
}
.addTimeLine-box {
  padding: 6.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .addTimeLine {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.38rem;
    height: 2.39rem;
    background: linear-gradient(215deg, #1db8ab, #179d92);
    border-radius: 0.63rem;
    font-size: 0.83rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 1;
  }
}
</style>
